import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";

function LossUser({ show, setShow, data }) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          To 10 trending dishes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S. no.</th>
                <th scope="col">Dish Name</th>
                <th scope="col">Revenue</th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 && data?.map((item,i)=>{
                return   <tr>
                <th scope="row">{i+1}</th>
                <td>{item.cancellationCount}</td>
                <td>{item.lostRevenue?.toFixed(2)}</td>
              </tr>
              })}
            
             
            </tbody>
          </table>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Ok</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LossUser;
