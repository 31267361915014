import { React, useState, useEffect } from "react";
import { FaArrowAltCircleRight, FaTrash } from "react-icons/fa";
import { MdMenuBook } from "react-icons/md";
import Select from "react-select";
import {  useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { api, baseUrl } from "../url";
import { selectCreater } from "../utils/custom-button";
import { Http } from "../http-dragon";
import Confirmation from "../components/confirmation";
import Swal from "sweetalert2";
import { errorToast } from "./logics/utils";
const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [subcategoryName, setSubCategoryName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [price, setPrice] = useState("");
  const [menu, setMenu] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [isConfimation, setIsConfimation] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  
  useEffect(() => {
    if (location.state._id) {
      fetchCategory();
      fetchSubCategory();
      fetchMenu();
    } else {
      navigate("/restaurant-lists");
    }
  }, []);
  const fetchMenu = () => {
    Http.loader(true);
    try {
      axios
        .post(baseUrl + api.getMenuByRestaurant, {
          restaurantID: location.state._id,
        })
        .then((res) => {
          setMenuList(res.data?.data);
          Http.loader(false);
        });
    } catch (error) {
      Http.loader(false);
      setMenuList([]);
      toast.error(error.response?.data?.message);
    }
    Http.loader(false);
  };
  const fetchCategory = () => {
    Http.loader(true);
    try {
      axios
        .post(baseUrl + api.getCategories, { restaurantID: location.state._id })
        .then((res) => {
          setCategoryList(res.data?.categories);
          Http.loader(false);
          selectCreater(res.data?.categories);
        });
    } catch (error) {
      Http.loader(false);
      setCategoryList([]);
      toast.error(error.response?.data?.message);
    }
    Http.loader(false);
  };
  const fetchSubCategory = (e) => {
    setCategoryName(e);
    Http.loader(true);
    try {
      axios
        .post(baseUrl + api.getSubCategories, { categoryID: e.value })
        .then((res) => {
          setSubCategoryList(res.data?.data);
          Http.loader(false);
          selectCreater(res.data?.data);
          fetchMenu();
        });
    } catch (error) {
      Http.loader(false);
      setCategoryList([]);
      toast.error(error.response?.data?.message);
    }
    Http.loader(false);
  };


  const addMenuCategory = () => {
    console.log("categoryName", location.state);
    if (!menu) {
      toast.error("Enter sub menu name..!");
      return;
    }
    if (!description) {
      toast.error("Enter description..!");
      return;
    }
    if (!categoryName) {
      toast.error("Enter category name..!");
      return;
    }

    if (!price) {
      toast.error("Enter Price..!");
      return;
    }
    let reqBody = {
      dishName: menu,
      description,
      price,
      restaurantId: location.state._id,
      categoryId: categoryName.value,
      subCategoryId: subcategoryName.value,
    };
    try {
      console.log("success");
      Http.loader(true);
      axios.post(baseUrl + api.addMenuDish, reqBody).then((res) => {
        toast.success(res.data?.message);
        Http.loader(false);
        fetchMenu();
        setCategoryName("");
        setSubCategoryName("");
        setMenu("");
        setDescription("")
        setPrice("");
      });
    } catch (error) {
      Http.loader(false);
      console.log("errror");
      toast.error(error.response?.data?.message);
    }
  };

  const menuHandler = (e) => {
    setMenu(e.target.value);
  };
  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };
  const goToMenuImage = () => {
    navigate("/menu-images", { state: { _id: location.state._id } });
  };
  const priceHandler = (e) => {
    setPrice(e.target.value);
  };
  const activeOrDeActive = async (_id, status) => {
    setIsConfimation(true)
    setConfirmationMsg(`Are you sure you want to ${status ? "DeActive" : "Active"} ?`)
    setDeleteId({dishId:_id, status:!status}) 
  }
  const deleteData =async (_id,status)=>{
    try {
      Http.loader(true)
      const res = await axios.post(baseUrl + api.changeDishStatus, deleteId)
      Http.loader(false)
      // getRestaurent({ value: deleteId?.owner })
      fetchMenu()
      toast.success(res?.data?.message)
    } catch (error) {
      Http.loader(false)
      toast.error(error?.response?.data?.message)
    }
  }
  const deleteMenu = (dishId)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(baseUrl+api.deleteMenuDish,{dishId}).then((res)=>{
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
          fetchMenu()
        }).catch((err)=>{
          errorToast(err)
        })
        
      }
    });

  }

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <MdMenuBook size="20" />
                        </span>
                      </span>
                      Menu
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="my-5 Menu">
            <div className="row justify-content-center px-4">
              <div className="col-md-12 shadowc bg-white py-3 rounded">
                <div className="row text-center">
                  <div className=" col-3 ">
                    <label htmlFor="Item">
                      <b>Item name</b> <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="menu"
                      value={menu}
                      onChange={menuHandler}
                    />
                  </div>

                  <div className="col-3">
                    <label>
                      <b>
                        Category <span className="text-danger">*</span>
                      </b>
                    </label>
                    <Select
                      options={categoryList}
                      value={categoryName}
                      onChange={fetchSubCategory}
                    />
                  </div>
                  <div className="col-3 ">
                    <label>
                      <b>Sub Category</b>
                    </label>
                    <Select
                      options={subCategoryList}
                      value={subcategoryName}
                      onChange={setSubCategoryName}
                    />
                  </div>
                  <div className=" col-md-3 ">
                    <label htmlFor="price">
                      <b>Price</b> <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="price"
                      value={price}
                      onChange={priceHandler}
                    />
                  </div>

                  <div className="col-6 mt-3">
                    <label htmlFor="Item">
                      <b>Decription</b> <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={3}
                      cols={3}
                      className="form-control"
                      value={description}
                      onChange={descriptionHandler}
                    ></textarea>
                  </div>

                  <div
                    class="col-md-1 align-self-center mt-4 pt-2 text-center"
                    onClick={addMenuCategory}
                  >
                    <div class="bg-gradient-primary text-white p-2 custom-button  ">
                      Add +
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="px-4">
            <div className="row justify-content-end">
              <div className="col-4 text-end mb-3" onClick={goToMenuImage}>
                <div class="sub-btn">
                  Add Menu Images
                  <FaArrowAltCircleRight />{" "}
                </div>
              </div>
              <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Item name</th>
                    <th>Price </th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                  {menuList?.map((el, i) => {
                    return (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        <th>{el.dishName}</th>
                        <th>{el.price}</th>

                        <th>{el.category}</th>
                        <th>{el.description}</th>
                        <td className="text-danger crPointer" onClick={()=>deleteMenu(el._id)}>
                          <FaTrash size={20} className="p-0" />
                        </td>
                        <td> <div className={`btn btn-${el?.active ? "danger text-white bg-danger" : "success text-white bg-success"} py-1 `} onClick={() => activeOrDeActive(el._id, el?.active, el?.owner)}> {el?.active ? "De-Activate" : "Activate"}</div></td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Confirmation show={isConfimation} setShow={setIsConfimation} message={confirmationMsg}deleteData={deleteData} />

    </>
  );
};
export default Menu;
