import { React, useEffect, useState } from "react";
import { FaTrash, FaUserPlus } from "react-icons/fa";
import axios from "axios";
import { api, baseUrl } from "../url";
import { toast } from "react-toastify";
import { Http } from "../http-dragon";
import { errorToast, successToast } from "./logics/utils";
import { BiCategory } from "react-icons/bi";

const GlobalCategories = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [catgoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    axios.get(baseUrl + api.getGlobalCategories).then((res) => {
      Http.loader(false)
      setCategoriesList(res.data.data);
    });
  };

  const onSave = () => {
    if (!name?.trim() || !image) {
      return toast.error("All fields required.");
    }

    const formData = new FormData();
    formData.append("categoryName", name);
    formData.append("image", image);
    
    Http.loader(true);
    axios
      .post(baseUrl + api.createGlobalCategory, formData)
      .then((res) => {
        fetchCategories();
        Http.loader(false);
        setName("");
        toast.success(res.data?.message)
        setImage("");
      })
      .catch((error) => {
        Http.loader(false);
        console.log(error);
        toast.error (error.response.data?.message)
      });
  };

  const deleteGlobalCategories =async (_id) =>{
    try{
     const res =await   axios.post(baseUrl+api.deleteGlobalCategory,{categoryID:_id})
     successToast(res)
     fetchCategories()
    }catch(error){
      errorToast(error)
    }
  }
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiCategory size="20" />
                        </span>
                      </span>
                      Global Categories
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4 ">
            <div>
              <form>
                <div className="row  mt-4 shadow-search">
                  <div className="col-12 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Name</b>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="col-12 col-md-4 mt-4 mt-lg-0">
                    <label htmlFor="applicant">
                      <b>Image</b>
                    </label>
                    <input
                      type="file"
                      name="idProof"
                      accept="image/*"
                      className="form-control"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </div>
                  <div className="col-12 col-md-4  mt-4  ">
                    <button
                      type="button"
                      disabled={!name.trim() || !image}
                      onClick={onSave}
                      className="bg-gradient-primary text-white p-2 custom-button padding-btn "
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>

          <section className="px-4 mb-5">
            <div className="row  mt-4 shadow-search">
              <div className="col-12">
                <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Name</th>
                    <th>Image</th>
                    <th>Delete</th>
                  </tr>
                  {catgoriesList.map((el, i) => {
                    return (
                      <tr key={el.name}>
                        <th>{i + 1}</th>
                        <th>{el.name}</th>
                        <td>
                          <img src={el.url} className="img-fluid gimg"  height={90} width={90}/>
                        </td>
                        <td className="text-danger" onClick={()=>deleteGlobalCategories(el._id)}><FaTrash size={20} className="p-0" /></td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default GlobalCategories;
