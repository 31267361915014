import { React, useEffect, useState } from 'react';
import { BiDetail } from "react-icons/bi";
import axios from 'axios';
import { api, baseUrl } from '../url';
import { toast } from 'react-toastify';
import { Http } from '../http-dragon';
import { dateEasyFormat, dateWithTime } from '../utils/date-formater';
import OrderDetails from '../components/order-details';
import No_Order from '../components/no-order';
const OrderStatus = () => {
  const [orderList, setOrderList] = useState([])
  const [selectedStatus, setSelectedStatus] = useState("")
  const [orderDetailPopup,setOrderDetailPopup] = useState(false)
  const [itemList,setItemList] = useState([])

  const [loginData, setLoginData] = useState(null)

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"))
    setLoginData(data)

  }, [])




  const handleStatus = (e) => {
    if (e.target.value) {
      setSelectedStatus(e.target.value)
      Http.loader(true)

      axios.post(baseUrl + api.getOrdersByOrderStatus, { orderStatus: e.target.value }).then(res => {
        setOrderList(res.data?.data)
        Http.loader(false)

      }).catch(error => {
        Http.loader(false)
        setOrderList([])

        toast.error(error.response.data.message)
      })
    } else {
      setSelectedStatus("")
    }

  }


  const handleItemList = (item)=>{
    setOrderDetailPopup(true)
    setItemList(item)
  }

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiDetail size='20' />

                        </span>
                      </span>
                      Order Status
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className='px-4 mt-4'>
          <div className="row justify-content-center">
          {loginData?.role === "SUPER_ADMIN" ? <div className="col-12 col-md-6 col-lg-4">
            <label htmlFor="applicant"><b>Select Status</b></label>
            <select
              name="orderStatus"
              id="cars"
              className="form-select"
              value={selectedStatus}
              onChange={handleStatus}
            >
              <option value="">
                Select Status...
              </option>
              <option value="pending">
                pending
              </option>
              <option value="confirm">
                confirm
              </option>
              <option value="preparing">
                preparing
              </option>
              <option value="served">served</option>

              <option value="prepared">
                prepared
              </option>

            </select>
          </div> : ""}
          </div>
          </section>
          <section className='px-4 mt-5'>

          <div className="row">
              <div className="mb-5">
                <section className="upload-recm ">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                        <div className="shadowc p-1  bg-hero table-responsive">
                          <table className="table order-status">
                            <thead>
                              <tr>
                                <th>Order Time</th>
                                <th>User Name</th>
                                <th>Restaurant Name</th>
                                <th>Final Amount</th>
                                <th>Cart Total</th>
                                <th>Date</th>
                                <th>Day</th>
                                <th>Start time</th>
                                <th>Table No.</th>
                                <th>Party Size</th>
                                <th>Status</th>
                                <th>Orders Type</th>
                                <th>Handle Payment</th>
                                <th>Items</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderList.length > 0 ? (
                                orderList.map((item) => {
                                  return (
                                    <tr>
                                      <td>{dateWithTime(item.updatedAt)} </td>
                                      <td>{item.user.name} </td>
                                      <td>{item.restaurant.name} </td>
                                      <td> {item.finalAmount?.toFixed(2)}</td>
                                      <td>{item.cartTotal?.toFixed(2)}</td>
                                      <td>{item?.preOrderDate ? dateEasyFormat(item?.preOrderDate):"-"}</td>
                                      <td>{item?.slot?.day}</td>
                                      <td className="text-danger">{item?.slot?.startTime} </td>  
                                          <td>{item?.tableNo >0 ? <button type="button" className="btn btn-success">{item.tableNo}</button>:"-"} </td>
                                      <td>{item?.partySize ? item?.partySize :"-"}</td>
                                      <td>
                                        <select
                                          name="orderStatus"
                                          id="cars"
                                          className="form-select"
                                          value={item.orderStatus}
                                          // onChange={(e) =>
                                          //   handleStatus(e, item._id)
                                          // }
                                        >
                                          <option value="pending">
                                            pending
                                          </option>
                                          <option value="confirm">
                                            confirm
                                          </option>
                                          <option value="preparing">
                                            preparing
                                          </option>
                                          <option value="served">served</option>
                                          <option value="completed">
                                            completed
                                          </option>
                                          <option value="prepared">
                                            prepared
                                          </option>
                                          <option value="cancelled">
                                            cancelled
                                          </option>
                                        </select>
                                      </td>
                                      <td>{item.orderType}</td>
                                      {item.paymentStatus ==="pending" ? <td ><button type="button" className="btn btn-warning">UnPaid </button></td>:
                                      <td ><button type="button" className="btn btn-success">Paid </button></td>}
                                      <td>
                                        <div
                                          className="sub-btn"
                                          onClick={()=>handleItemList(item)}
                                        >
                                          See more
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                               <tr className="text-center bg-white">
                                <td colSpan="12"><No_Order/></td>
                               </tr>
                                  
                                  
                              
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      </div>

                  </div>
                </section>

           
              </div>
             
            </div>
          </section>
        </div>
      </main>
      <OrderDetails show={orderDetailPopup} setShow={setOrderDetailPopup} itemList={itemList}/>


    </>
  );
}
export default OrderStatus;