import React, { useContext } from "react";
import { FaBell } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { OrderContext } from "../loginData/RealTimeOrderProvider"


const Notifications = () => {

    const { waiterNotification,setWaiterNotification } = useContext(OrderContext)
   
    const deleteNotification = (index)=>{
        waiterNotification.splice(index,1)
        setWaiterNotification([...waiterNotification])
       
    }

    return (


        <>
            <main id="main">
                <div className="content-wrapper">
                    <div className="page-nav">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="page-header ms-2">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                                <span>
                                                    <FaBell size="20" />
                                                </span>
                                            </span>
                                            Notifications

                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="mt-5 restaurant-edit">


                        <div className="row">
                            <div className="my-5">
                                <section className="upload-recm ">
                                    <div className="container-fluid">
                                        <div className="row justify-content-center">
                                            <div className="col-md-8 bg-white p-lg-3 p-2 table-shadow mb-5">
                                                <div className="shadowc p-1  bg-hero">
                                                    <table className="table text-center">
                                                        <thead>
                                                            <tr>
                                                                <th>Table No.</th>
                                                                <th>Order No.</th>
                                                                <th>Action</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {waiterNotification.map((notification,i) => {
                                                                return <tr className="bg-white">
                                                                    <td>{notification.tableNo}</td>
                                                                    <td>{notification.orderNumber}</td>
                                                                    <td onClick={()=>deleteNotification(i)}><ImCross className="cross fs-5" /></td>
                                                                </tr>
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </section>

                            </div>

                        </div>


                    </section>
                </div>
            </main>




        </>
    )
}

export default Notifications;