import { React, useEffect, useState } from 'react';
import { FaChair, FaTrash } from "react-icons/fa";
import {  useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api, baseUrl } from '../url';
import { errorToast, successToast } from './logics/utils';
import { Http } from '../http-dragon';
import { toast } from 'react-toastify';
import Confirmation from '../components/confirmation';


const colors_enum = {
  monday:"bg-primary",
  tuesday:"bg-success",
  wednesday:"bg-info",
  thursday:"bg-secondary",
  friday:"bg-warning",
  saturday:"bg-danger",
  sunday:"bg-dark"
}

const TimeSlots = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [startTime, setStartTime] = useState("")
  const [slotList, setSlotList] = useState([])
  const [confirmationMsg, setConfirmationMsg] = useState("")
  const [isConfimation, setIsConfimation] = useState(false)
  const [slotID, setSlotID] = useState({})
  const [day,setDay] = useState("")


  useEffect(() => {
    if (location?.state?._id) {
      fetchSlots()
    } else {
      navigate("/restaurant-lists")
    }
  }, [])



  const fetchSlots = async () => {
    Http.loader(true)
    try {
      const res = await axios.post(baseUrl + api.getTimeSlot, { restaurantID: location?.state?._id })
      Http.loader(false)
      setSlotList(res.data?.data)
    } catch (error) {
      Http.loader(false)
      errorToast(error)
    }
    Http.loader(false)
  }

  const saveSlot = async () => {

    if (!startTime) {
      toast.error("Start time is required..")
      return
    }
    
   

    if(!day){
      toast.error("Day is required...")
      return;
    }

   
    Http.loader(true)
    try {
      const res = await axios.post(baseUrl + api.createTimeSlot, { startTime,  day, restaurantID: location?.state?._id })
      successToast(res)
      setStartTime("")
      Http.loader(false)
      fetchSlots()
    } catch (error) {
      errorToast(error)
      Http.loader(false)
    }
  }
  const deleteSlot = async (slotId) => {
    Http.loader(true)
    try {
      const res = await axios.post(baseUrl + api.deleteTimeSlot, { slotId })
      successToast(res)
      Http.loader(false)
      fetchSlots()
    } catch (error) {
      Http.loader(false)
      errorToast(error)
    }
    Http.loader(false)
  }
  const activeOrDeActive = async (_id, status) => {
    setIsConfimation(true)
    setConfirmationMsg(`Are you sure you want to ${status ? "DeActive" : "Active"} ?`)
    setSlotID({ slotID: _id, active: !status })
  }
  const deleteData = async (_id, status) => {
    try {
      Http.loader(true)
      const res = await axios.post(baseUrl + api.changeSlotStatus, slotID)
      Http.loader(false)
      fetchSlots()
      successToast(res)
    } catch (error) {
      Http.loader(false)
      errorToast(error)
    }
  }
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaChair size='20' />

                        </span>
                      </span>
                      Time Slots
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className='my-5 sitting'>
            <div className="row justify-content-center">
              <div className="col-md-4 shadowc bg-white py-3 rounded">
                <div className="row text-center">
                  <div className="col-6">
                    <label><b>Start Time.<span className='text-danger'>*</span></b></label>
                    <input type='time' className='form-control' value={startTime} onChange={(e) => setStartTime(e.target.value)} />

                  </div>

                  <div className='col-6 mt-4'>
                    <select
                      name="orderStatus"
                      id="cars"
                      className="form-select"
                      value={day}
                      onChange={(e) =>setDay(e.target.value)}


                    >
                      <option value="" selected disabled hidden>
                        Select Day
                      </option>
                      <option value="monday">
                        Monday
                      </option>
                      <option value="tuesday">
                        Tuesday
                      </option>
                      <option value="wednesday">
                        Wednesday
                      </option>
                      <option value="thursday">
                        Thursday
                      </option>
                      <option value="friday">
                        Friday
                      </option>
                      <option value="saturday">
                        Saturday
                      </option>
                      <option value="sunday">
                        Sunday
                      </option>
                    </select>
                  </div>
                </div>




              </div>

              <div className="col-md-2 align-self-center " onClick={saveSlot}>
                <div class="bg-gradient-primary text-white p-2 custom-button  " >Add +</div>
              </div>
            </div>


          </section>
          <section className='px-4'>

            <div className="row">
              <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                <table class="table">
                  <thead>
                    <tr>
                      {/* <th scope="col">Sr.No</th> */}
                      <th scope='col'>Day</th>
                      <th scope="col">Start Time</th>
                      <th scope="col">Delete</th>
                      <th scope="col">Status</th>

                    </tr>
                  </thead>
                  <tbody>
                    {slotList?.length > 0 ? slotList.map((item, i) => {
                      return <tr>
                        {/* <th scope="row">{i + 1}</th> */}
                        <td className={colors_enum[item.day]} style={{color:"white",fontWeight:800}}>{item.day.toUpperCase()}</td>
                        <td>{item.startTime}</td>
                        <td><div className={`btn btn-${item?.active ? "danger" : "success"} pt-0 pb-1`} onClick={() => activeOrDeActive(item._id, item?.active, item?.owner)}> {item?.active ? "De-Activate" : "Activate"}</div></td>
                        <td className='text-danger' onClick={() => deleteSlot(item._id)}><FaTrash /></td>

                      </tr>
                    }) : ""}


                  </tbody>
                </table>

              </div>
            </div>
          </section>
        </div>
      </main>
      <Confirmation show={isConfimation} setShow={setIsConfimation} message={confirmationMsg} deleteData={deleteData} />

    </>
  );
}
export default TimeSlots;