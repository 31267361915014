import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Confirmation({ show, setShow, message, deleteData,id=null }) {


    const yesDelete = () => {
        deleteData(id)
        setShow(false)
    };
    const handleClose = () => setShow(false);

    return (
        <>


            <Modal show={show} >
                <Modal.Header >
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={yesDelete}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Confirmation;