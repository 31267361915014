import React, { useEffect, useState } from "react";

import { FaBell, FaHome, FaHotel, FaListAlt, FaShoppingBag, FaUserAlt } from "react-icons/fa";
import { NavLink,  useNavigate } from "react-router-dom";
import { BiCategory, BiDetail } from "react-icons/bi";
import { IoBagCheckSharp } from "react-icons/io5";
import { BsBellFill } from "react-icons/bs";
const Sidebar = () => {
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState(null);
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"));
    if (data) {
      setLoginData(data);
    }
  }, []);

  const editOwner = () => {
    navigate("/restaurant-edit", {
      state: { _id: loginData.restaurant[0]?._id },
    });
  };
  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <NavLink
              to="/"
              activeClassName="nav-link"
              className="d-flex nav-link-c"
            >
              <span className="menu-icon">
                <FaHome />
              </span>
              <span className="menu-title">Dashboard</span>
            </NavLink>
          </li>
       
          {loginData?.role === "SUPER_ADMIN" && (
            <>
              <li className="nav-item">
                <NavLink
                  to="/restaurant-onboarding"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <FaHotel />
                  </span>
                  <span className="menu-title">Onboarding</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/owner-lists"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <FaUserAlt />
                  </span>
                  <span className="menu-title">Owner Lists</span>
                </NavLink>
              </li>
            </>
          )}
          {(loginData?.role === "SUPER_ADMIN" ||
            loginData?.role === "OWNER") && (
            <li className="nav-item">
              <NavLink
                to="/restaurant-lists"
                activeClassName="nav-link"
                className="d-flex nav-link-c"
              >
                <span className="menu-icon">
                  <BiDetail />
                </span>
                <span className="menu-title">Restaurant Lists</span>
              </NavLink>
            </li>
          )}
          {loginData?.role === "MANAGER" && (
            <li className="nav-item">
              <div
                onClick={editOwner}
                activeClassName="nav-link"
                className="d-flex nav-link-c"
              >
                <span className="menu-icon">
                  <BiDetail />
                </span>
                <span className="menu-title crPointer">Manage Dishes</span>
              </div>
            </li>
          )}
          {loginData?.role === "SUPER_ADMIN" && (
            <>
              <li className="nav-item">
                <NavLink
                  to="/global-categories"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <BiCategory />
                  </span>
                  <span className="menu-title">Global categories</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/restaurant-categories"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <BiCategory />
                  </span>
                  <span className="menu-title">Restaurant categories</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/level-2"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <BiCategory />
                  </span>
                  <span className="menu-title">Level 2 categories</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/add-level-2"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <BiCategory />
                  </span>
                  <span className="menu-title">Add Level 2 categories</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/order-status"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <IoBagCheckSharp />
                  </span>
                  <span className="menu-title">Order Status</span>
                </NavLink>
              </li>
            </>
          )}
          <li className="nav-item">
            <NavLink
              to="/orders"
              activeClassName="nav-link"
              className="d-flex nav-link-c"
            >
              <span className="menu-icon">
                <FaShoppingBag />
              </span>
              <span className="menu-title">Orders</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/order-history"
              activeClassName="nav-link"
              className="d-flex nav-link-c"
            >
              <span className="menu-icon">
                <FaListAlt />
              </span>
              <span className="menu-title">Order History</span>
            </NavLink>
          </li>
       
          <li className="nav-item">
            <NavLink
              to="/notifications"
              activeClassName="nav-link"
              className="d-flex nav-link-c"
            >
              <span className="menu-icon">
                <BsBellFill />
              </span>
              <span className="menu-title">Waiter Notifications</span>
            </NavLink>
          </li>
          {loginData?.role === "SUPER_ADMIN" && (
          <li className="nav-item">
            <NavLink
              to="/send-notifications"
              activeClassName="nav-link"
              className="d-flex nav-link-c"
            >
              <span className="menu-icon">
                <FaBell />
              </span>
              <span className="menu-title">Send Notifications</span>
            </NavLink>
          </li>)}
       
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
