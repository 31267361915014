import React from 'react';
const Footer = () => {
 
  return (
  <>  
  <footer id="footer" class="footer">
    <div class="copyright">
      &copy;{new Date(). getFullYear() } Copyright <strong><a href='' target='_blank'>Bhukkads</a></strong>. All Rights Reserved
    </div>
    
  </footer>
  </>
  );
}

export default Footer;