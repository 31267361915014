import { React, useEffect, useState } from "react";
import { FaTrash, FaUserPlus } from "react-icons/fa";
import axios from "axios";
import { api, baseUrl } from "../url";
import { toast, ToastContainer } from "react-toastify";
import { Http } from "../http-dragon";
import { errorToast, successToast } from "./logics/utils";
import { BiCategory } from "react-icons/bi";

const Level2 = () => {
  const [name, setName] = useState("");
  const [level2CatgoriesList, setLevel2CategoriesList] = useState([]);

  useEffect(() => {
    fetchLevel2Categories();
  }, []);

  const fetchLevel2Categories = () => {
    Http.loader(true)
    axios.get(baseUrl + api.getLevelTwoCategories).then((res) => {
      Http.loader(false)
      setLevel2CategoriesList(res.data?.data);
    });
    Http.loader(false)
  };

  const onSave = () => {
    if (!name.trim()) {
      return toast.error("Category name is required.");
    }

    
    Http.loader(true);
    axios
      .post(baseUrl + api.addLevelTwoCategory, {categoryName:name})
      .then((res) => {
        fetchLevel2Categories();
        Http.loader(false);
        setName("");
        successToast(res)
      })
      .catch((error) => {
        Http.loader(false);
        console.log(error);
        errorToast(error)
      });
  };

  const deleteLevel2GlobalCategories =async (_id) =>{
    try{
        Http.loader(true)
     const res =await   axios.post(baseUrl+api.deleteDevelTwoCategory,{categoryID:_id})
     successToast(res)
     Http.loader(false)
     fetchLevel2Categories()
    }catch(error){
     Http.loader(false)
      errorToast(error)
    }
  }
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiCategory size="20" />
                        </span>
                      </span>
                      Level 2 Categories
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4">
            <div>
              <form>
                <div className="row  mt-4 shadow-search">
                  <div className="col-12 col-md-6 ">
                    <label htmlFor="applicant">
                      <b>Name</b>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

               
                  <div className="col-12 col-md-4  mt-4  ">
                    <button
                      type="button"
                      disabled={!name.trim()}
                      onClick={onSave}
                      className="bg-gradient-primary text-white p-2 custom-button padding-btn "
                    >
                      save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>

          <section className="px-4">
            <div className="row  mt-4 shadow-search">
              <div className="col-12">
                <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Name</th>
                    <th>Delete</th>
                  </tr>
                  {level2CatgoriesList.map((el, i) => {
                    return (
                      <tr key={el.name}>
                        <th>{i + 1}</th>
                        <th>{el.name}</th>
                        <td className="text-danger" onClick={()=>deleteLevel2GlobalCategories(el._id)}><FaTrash size={20} className="p-0" /></td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default Level2;
