export const baseUrl = "https://bhukkadadminbackend.bhukkads.in/"
// export const baseUrl = "http://localhost:5500/"

export const api = {
    getCategories : "get-categories",
    createCategory:"create-category",            
    getMenuByRestaurant:"get-menu-by-restaurant",
    deleteDishImage:"delete-dish-image",
    addMenuDishImages:"add-menu-dish-images",
    getDishImages:"get-dish-images",
    getSubCategories:"get-sub-categories",
    addMenuDish:"add-menu-dish",
    updateOwner:"update-owner",
    createOwner:"create-owner",
    getOwners:"get-owners",
    getSingleRestaurant:"get-single-restaurant",
    updateRestaurant:"update-restaurant",
    restaurant:"restaurant",
    getRestaurantImages:"get-restaurant-images",
    deleteRestaurantImage:"delete-restaurant-image",
    uploadRestaurantImages:"upload-restaurant-images",
    getRestaurantByOwner:"get-restaurant-by-owner",
    createSubCategory:"create-sub-category",
    getGlobalCategories:"get-global-categories",
    createGlobalCategory:"create-global-category",
    activeOrDeActiveRestaurant:"active-deactive-restaurant",
    deleteGlobalCategory:"delete-global-category",
    activeOrDeActiveOwner:"active-deactive-owner",
    addTableAndChairsOfRestaurant:"add-table-and-Chairs-of-Restaurant",
    getTableAndChairsOfRestaurant:"get-Table-And-Chairs-Of-Restaurant",
    deleteTables:"delete-tables",
    addLevelTwoCategory:"add-level-two-category",
    getLevelTwoCategories:"get-level-two-categories",
    deleteDevelTwoCategory:"delete-level-two-category",
    getRestaurantsByName: "get-restaurants-by-name",
    addLevelTwoCategoryOfRestaurant: "add-level-two-category-of-restaurant",
    createOffer:"create-offer",
    getOfferByRestaurantId:"get-offer-by-restaurant-id",
    deleteOfferById:"delete-offer-by-id",
    createManager:"create-manager",
    userLogin:"auth/login",
    getAllManagers:"get-all-managers",
    deleteManager:"delete-manager",
    findOrdersByOrderType:"find-orders-by-order-type",
    createTimeSlot:"create-time-slot",
    getTimeSlot:"get-time-slot",
    deleteTimeSlot:"delete-time-slot",
    ownersById:"owners-by-id",
    changeSlotStatus:"change-slot-status",
    deleteCategory:"delete-category",
    changeOrderStatus:"change-order-status",
    findOrdersHistoryByOrderType:"find-orders-history-by-order-type",
    findAllOrders:"find-all-orders",
    changePaymentStatus:"change-payment-status",
    createGlobalCategoryTwo:"create-global-category-two",
    getGlobalCategoriesTwo:"get-global-categories-two",
    deleteGlobalCategoryTwo:"delete-global-category-two",
    getOrdersByOrderStatus:"get-orders-by-order-status",
    updateCategories:"update-categories",
    changeDishStatus:"change-dish-status",
    deleteMenuDish:"delete-menu-dish",
    changeRestaurantOpenStatus:"change-restaurant-open-status",
    sendNotifications:"send-notifications",
    getRevenueByRestaurant:"get-revenue-by-restaurant",
    itemsRevenue:"items-revenue",
    lifeTimeValue:"life-time-value",
    getTotalRevenue:"get-total-revenue",
    lostRevenue:"lost-revenue"

}
