import React,{createContext,useState} from "react";

const OrderContext = createContext()

const RealTimeOrderProvider = ({children}) =>{
  const [waiterNotification,setWaiterNotification] = useState([])
   
  return (
    <OrderContext.Provider value={{waiterNotification,setWaiterNotification}}>
         {children}
    </OrderContext.Provider>
  )
}

export {RealTimeOrderProvider,OrderContext}