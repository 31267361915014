import React from "react";
import noorder from "../assets/img/noorder.webp"
const No_Order=()=>
{

    return(
        <>
        <div className="py-4">
        <img src={noorder} alt="" height={150} width={150} className="img-fluid" />
        
        <p className="text-no-order">NO ORDER FOUND</p>
        </div>
        </>
    )
}
export default No_Order